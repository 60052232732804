import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Element } from 'react-scroll';
import { makeLinkTargetNameFromTitle } from '~utils/unfurl-helpers';
import RichText from '~components/RichText';
import { FaqExpansionPanel, FaqContentsNavigation } from './components';

const FaqSections = ({ faqSections = [] }) => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid container direction="row" justify="space-around" alignItems="flex-start">
      <Grid
        item
        xs={12}
        sm={3}
        style={{ position: 'sticky', top: isSmallerThanMd ? '48px' : '76px' }}
      >
        <FaqContentsNavigation sections={faqSections} />
      </Grid>
      <Grid item xs={12} sm={7}>
        {faqSections &&
          faqSections.map((section, index) => {
            const title = section && section.section_title && section.section_title.html;
            const name = makeLinkTargetNameFromTitle(title);
            return (
              <Element key={title} name={name}>
                <Box px={isXs ? 2 : 0} width="100%">
                  <RichText html={title} mode="faqHeroTitle" />
                  <FaqExpansionPanel sections={section && section.sections} index={index} />
                </Box>
              </Element>
            );
          })}
      </Grid>
    </Grid>
  );
};

FaqSections.propTypes = {
  faqSections: PropTypes.arrayOf(
    PropTypes.shape({
      section_title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          topic_title: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired,
          topic_content: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired
        })
      ).isRequired
    }).isRequired
  ).isRequired
};

export default FaqSections;
