import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Scroll from 'react-scroll';
import RichText from '~components/RichText';
import Divider from '~components/Divider';
import { makeLinkTargetNameFromTitle } from '~utils/unfurl-helpers';

const GoToSection = Scroll.Link;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    }
  },
  active: {
    color: '#458FCE'
  },

  sectionHeading: {
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '66px',
    marginTop: '80px',
    marginBottom: '70px'
  },

  heading: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#458FCE'
  },
  paragraph: {
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '15px'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  expansionPanel: {
    display: 'block'
  },
  expansionSummarySpacing: {
    margin: '24px 0'
  }
}));

const FaqContentsNavigation = ({ sections }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  if (isXs) {
    return null;
  }
  return (
    <Box
      bgcolor="#E3F0FA"
      py={isSmallerThanMd ? 2 : 8}
      pl={isSmallerThanMd ? 2 : 11}
      pr={isSmallerThanMd ? 2 : 8}
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: '500', fontSize: '36px', lineHeight: '42px', marginBottom: '65px' }}
      >
        Contents
      </Typography>
      {sections &&
        sections.map((section, index) => (
          <GoToSection
            to={makeLinkTargetNameFromTitle(
              section && section.section_title && section.section_title.html
            )}
            smooth
            offset={-80}
            duration={500}
            activeClass={classes.active}
            key={section && section.section_title && section.section_title.html}
            style={{ cursor: 'pointer' }}
          >
            <RichText
              html={section && section.section_title && section.section_title.html}
              mode="faqNavigationLink"
              verticalSpacing={0}
            />
            {index !== sections.length - 1 && <Divider spacing={2} />}
          </GoToSection>
        ))}
    </Box>
  );
};

FaqContentsNavigation.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section_title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          topic_title: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired,
          topic_content: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired
        })
      ).isRequired
    }).isRequired
  ).isRequired
};

export default FaqContentsNavigation;
