import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RichText from '~components/RichText';
import Container from '~components/Container';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    }
  },
  expansionPanel: {
    display: 'block',
    padding: '0px'
  },
  expansionSummarySpacing: {
    margin: '0px 0px',
    padding: '0px 0px',
    display: 'inline-flex'
  }
}));

const FaqExpansionPanel = ({ sections, index }) => {
  const classes = useStyles();
  const defaultExpandedPanel =
    sections &&
    index === 0 &&
    sections[0] &&
    sections[0].topic_title &&
    sections[0].topic_title.html;
  const [expanded, setExpanded] = React.useState(defaultExpandedPanel || '');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container style={{ paddingLeft: '0px' }}>
      <div className={classes.root}>
        {sections &&
          sections.map(section => {
            const title = section && section.topic_title && section.topic_title.html;
            const content = section && section.topic_content && section.topic_content.html;
            return (
              <Accordion
                key={title}
                expanded={expanded === title}
                onChange={handleChange(section && title)}
                style={{ boxShadow: 'unset', border: 'unset' }}
              >
                <AccordionSummary
                  aria-controls={title}
                  id={title}
                  className={classes.expansionSummarySpacing}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <RichText html={title} mode="faqTopicTitle" />
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanel}>
                  <RichText html={content} mode="faqTopicContent" />
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </Container>
  );
};

FaqExpansionPanel.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      topic_title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      topic_content: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  index: PropTypes.number.isRequired
};

export default FaqExpansionPanel;
